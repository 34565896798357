import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Header from './../components/Header';
import PdfViewer from './../components/PdfViewer';
import Partners from './../components/Partners';

class CompanyListPage extends Component {
  render() {
    return (
      <div>
        <Header place="VUT fakulta Podnikatelská" date="10. Dubna"/>
        <PdfViewer />
      </div>
    );
  }
}

export default CompanyListPage;
