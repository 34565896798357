// ButtonForm.tsx
import React from 'react';
import './ButtonForm.css';

interface ButtonFormProps {
  toggledButtonsMajorA: Set<string>;
  setToggledButtonsMajorA: React.Dispatch<React.SetStateAction<Set<string>>>;
  toggledButtonsMajorB: Set<string>;
  setToggledButtonsMajorB: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const ButtonForm: React.FC<ButtonFormProps> = ({
  toggledButtonsMajorA,
  setToggledButtonsMajorA,
  toggledButtonsMajorB,
  setToggledButtonsMajorB,
}) => {
  // Component implementation
  const handleButtonClickMajorA = (buttonId: string) => {
    setToggledButtonsMajorA(prevState => {
      const newState = new Set(prevState);
      if (newState.has(buttonId)) {
        newState.delete(buttonId);
      } else {
        newState.add(buttonId);
      }
      return newState;
    });
  };

  // Function to handle button click for MajorB
  const handleButtonClickMajorB = (buttonId: string) => {
    setToggledButtonsMajorB(prevState => {
      const newState = new Set(prevState);
      if (newState.has(buttonId)) {
        newState.delete(buttonId);
      } else {
        newState.add(buttonId);
      }
      return newState;
    });
  };

  return (
    <form>
      {/* MajorA Buttons */}
      <div className='obor-container'>
        <h4>Obor</h4>
        {["Strojní","Elektro", "Informatika", "Ekonomie", "Chemie", "Stavebnictví", "Ostatní"].map((num) => {
          const buttonId = `${num}`;
          return (
            <button
              key={num}
              id={buttonId}
              type="button"
              onClick={() => handleButtonClickMajorA(buttonId)}
              className='button'
              style={{
                backgroundColor: toggledButtonsMajorA.has(buttonId) ? '#1b75bb' : 'white',
                color: toggledButtonsMajorA.has(buttonId) ? 'white' : 'black',
              }}
            >
              {num}
            </button>
          );
        })}
      </div>
      <br/> 
      {/* MajorB Buttons */}
      <div className='uvazek-container'>
        <h4>Druh úvazku</h4>
        {["Plný úvazek", "Částečný úvazek", "Diplomka", "Stáž", "Brigáda"].map((num) => {
          const buttonId = `${num}`;
          return (
            <button
            key={num}
            id={buttonId}
            type="button"
            onClick={() => handleButtonClickMajorB(buttonId)}
            className='button'
            style={{
              backgroundColor: toggledButtonsMajorB.has(buttonId) ? '#1b75bb' : 'white',
              color: toggledButtonsMajorB.has(buttonId) ? 'white' : 'black',
            }}
          >
            {num}
          </button>
          );
        })}
      </div>
    </form>
  );
};

export default ButtonForm;
