import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import HomePage from './pages/Home';
import CompanyListPage from './pages/CompanyList';
import AboutUsPage from './pages/AboutUs';
import ForCompaniesPage from './pages/ForCompanies';
import JobfairPlanPage from './pages/JobfairPlan';
import AdminPage from './pages/Admin';
import ContestPage from './pages/Contest';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import PreviousYear from './pages/PreviousYear';
import ScrollToTop from './components/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  <Router>
    <ScrollToTop/>
  <Routes>
        <Route index element={<HomePage/>} />
        <Route path='/company-list' element={<CompanyListPage/>} />
        <Route path='/about' element={<AboutUsPage/>} />
        <Route path='/for-companies' element={<ForCompaniesPage/>} />
        <Route path='/plan' element={<JobfairPlanPage/>} />
        <Route path='/admin/*' element={<AdminPage/>} />
        <Route path='/contest' element={<ContestPage/>} />
        <Route path='/previous-years' element={<PreviousYear/>} />

  </Routes>
  </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
