import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './About.css';
import { Link } from "react-router-dom";

class About extends Component {
  state = {
    copied: false,
  };

  copyEmail = () => {
    const email = "brno@iaeste.cz";

    // Check if the user is on mobile
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Open email app on mobile
      window.location.href = `mailto:${email}`;
    } else {
      // Copy email on desktop
      navigator.clipboard.writeText(email).then(() => {
        this.setState({ copied: true });

        // Show tooltip
        const emailDiv = document.querySelector(".email-copy");
        if (emailDiv) {
          emailDiv.classList.add("active");

          setTimeout(() => {
            this.setState({ copied: false });
            emailDiv.classList.remove("active");
          }, 2000);
        }
      });
    }
  };



  render() {
    return (
      <div className="container-column pt-24">

        <Container>
          <div className="promote-text"><h2>Veletrh iKariéra v Brně organizuje IAESTE LC Brno</h2></div>
          <img src="data/images/SpolocnaFoto2024.JPG" className="organisation-wide" alt="iaeste" />
          <a href="https://join.iaeste.cz/home" target="_blank" rel="noopener noreferrer" className="button-link-join-us">
            Zjisti o nás více a přidej se k nám!
          </a>
          <div className="container-row-start">
            <div className="info-box">
              <div className="container-icon-title">
                <img src="data/icons/info.svg" alt="Hello" />
                <div className="info-title"> Co o nás nevíš? </div>
              </div>
              <div className="info-list">
                <div className="info-list-points">
                  <ul>
                    <li>Vznikli jsme v roce 1948</li>
                    <li>IAESTE zajišťuje odbornou praxi v řadě podniků, výzkumných ústavů, laboratoří a dalších institucí</li>
                    <li>Jsme mezinárodní organizace pro výměnu studentů za účelem získání technické praxe </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="info-box">
              <div className="container-icon-title">
                <img src="data/icons/add.svg" alt="Hello" />
                <div className="info-title"> Co se u nás naučíš? </div>
              </div>
              <div className="info-list">
                <div className="info-list-points">
                  <ul>
                    <li>Práci v týmů a vůdcovské dovednosti</li>
                    <li>Zlepšíš si angličtinu</li>
                    <li>Osvojíš si práci s grafickými programy, marketingem a to nejen na sociálních sítích</li>
                    <li>Komunikační a vyjednávací dovednosti, schopnost vést B2B obchodní jednání</li>
                  </ul>
                </div>
              </div>

            </div>
            <div className="info-box">
              <div className="container-icon-title">
                <img src="data/icons/bookmark.svg" alt="Hello" />
                <div className="info-title"> Jaké zázemí ti poskytneme? </div>
              </div>
              <div className="info-list">
                <div className="info-list-points">
                  <ul>
                    <li>Podporující komunitu příjemných lidí</li>
                    <li>Sdílenou kancelář</li>
                    <li>Licence prémiových programů</li>
                    <li>Servery a výpočetní techniku</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="container_socials">
            <div>
              Více o <b>vycestování na stáž</b> se dozvíš na našich stránkách a další <b>pracovní nabídky</b> najdeš na portálu iKariéra.
            </div>
            <div className="container_info">
              <a href="https://www.iaeste.cz/" target="_blank" rel="noopener noreferrer">
                <img src="data/icons/IAESTE Czech Republic Vertical-01.png" className="" alt="Hello" /></a>
              <a href="https://www.ikariera.cz/" target="_blank" rel="noopener noreferrer">
                <img src="data/icons/ikariera-znak_lightblue.png" alt="Hello" className="tie" /></a>
            </div>
          </div>
          <div className="container_socials">
            <div>
              Napiš nám email nebo se ozvi přes sociální sítě!
            </div>
            <div className="container_logos">
              <div onClick={this.copyEmail} className="email-copy">
                <img src="data/icons/at-solid.svg" alt="Email Icon" />
                <span className="tooltip-text">
                  E-mail brno@iaeste.cz zkopírován do schránky!
                </span>
              </div>
              <a href="https://www.facebook.com/iaestebrno" target="_blank" rel="noopener noreferrer">
                <img src="data/icons/facebook-brands-solid.svg" className="" alt="Hello" /></a>
              <a href="https://www.instagram.com/iaeste.brno" target="_blank" rel="noopener noreferrer">
                <img src="data/icons/instagram-brands-solid.svg" className="" alt="Hello" /></a>
              <a href="https://www.youtube.com/@IAESTECzechRepublic" target="_blank" rel="noopener noreferrer">
                <img src="data/icons/youtube-brands-solid.svg" className="" alt="Hello" /></a>
            </div>
          </div>

        </Container>

      </div>
    );
  }
}

export default About;
