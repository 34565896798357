import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import './Timeout.css';

class Timeout extends Component {
  render() {
    return (
      <Container className="video-center" >
          <Col md={4}>
         <p className='mt-96 '/>
          <Container className="text-button-container">
          <p className="video-text-main"><b>31. ročník Veletrhu iKariéra v Brně se bude konat ve středu 9.&nbsp;dubna&nbsp;2025</b></p>
          <p className="video-text">
          Pojď na Fakultu podnikatelskou VUT navázat kontakt s personalisty z více než 50 firem, prohlédni si otevřené pozice a zjisti více o zaměstnání ve tvém oboru.
          </p>
          <div className="button-main">
          <AddToCalendarButton
            name="Veletrh Pracovních príležitostí iKariéra v Brne"
            label="Přidat do kalendáře!"
            size="15"
            lightMode="light"
            startDate="2025-04-09"
            options="['Apple', 'Google', 'iCal']"
          ></AddToCalendarButton>
          </div>
          </Container>
          </Col>
       </Container>
    );
  }
}

export default Timeout;
