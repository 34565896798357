import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './Video.css'

interface VideoState {
  videoLoaded: boolean;
}

class Video extends Component<{}, VideoState> {
  constructor(props:any) {
    super(props);
    // Initialize the state to keep track of video's loading status
    this.state = {
      videoLoaded: false,
    };
  }

  handleVideoLoad = () => {
    // Update the state when the video is loaded
    this.setState({ videoLoaded: true });
  };

  render() {
    const { videoLoaded } = this.state;
    return (
      <div>
        {/* Placeholder image */}
        {!videoLoaded && (
          <img
            src="static/ikariera-compressed.png"
            alt="Loading video"
            className="video-placeholder hidden-mobile"
          />
        )}

        {/* Video for non-mobile devices */}
        <video
          src="static/ikariera-compressed.mp4"
          className={`hidden-mobile ${videoLoaded ? 'show' : 'hide'}`}
          autoPlay
          loop
          muted
          onLoadedData={this.handleVideoLoad}
        />

        <video
          src="static/ikariera-mobile.mp4"
          className={`hidden-desktop ${videoLoaded ? 'show' : 'hide'}`}
          autoPlay
          loop
          muted
          onLoadedData={this.handleVideoLoad}
        />

    
        {/* Video for mobile devices */}

      </div>
    );
  }
}

export default Video;
