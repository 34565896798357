import React, { Component } from 'react';
import Header from './../components/Header';
import Contest from '../components/Contest';
import Partners from '../components/Partners';

class ContestPage extends Component {
  render() {
    return (
      <div className="contest-page bg-gray-900">

        <Header place="VUT fakulta Podnikatelská" date="12. Dubna" />

        <div className='main-content'>
          <div className='pt-40'>
            <div className='text-center font-medium text-3xl text-white mb-5'>Více informací o soutěži už brzy!</div>
          </div>
          {/* <Contest button={false} /> */}
        </div>

        <Partners />
      </div>
    );
  }
}

export default ContestPage;
